<template>
	<div class="data-report">
		<div class="title-card">
			<div class="title-box">
				<div class="title">资料报告库</div>
			</div>
			<div class="search-box">
				<div class="search-form">
					<div class="form-item">
						<div class="form-top">
							<div class="form-lable">标题搜索</div>
							<div>
								<el-input v-model="conPO.datumTitle" placeholder="请输入标题" maxlength="200" clearable></el-input>
							</div>
							<div class="form-lable lable">分类</div>
							<div>
								<el-select v-model="conPO.categoryId" filterable placeholder="请选择分类" clearable>
									<el-option v-for="(item, index) in typeList" :key="index" :label="item.categoryName" :value="item.categoryId"></el-option>
								</el-select>
							</div>
							<div class="form-lable lable">发文时间</div>
							<div class="date-picker">
								<el-date-picker v-model="conPO.dateStart" type="month" :picker-options="dateStart" value-format="yyyy-MM" placeholder="选择开始日期"></el-date-picker>
								<!-- <div class="iconfont-box">
                  <span class="iconfont icon-riliriqi2"></span>
                </div> -->
							</div>
							<div class="line"></div>
							<div class="date-picker">
								<el-date-picker v-model="conPO.dateEnd" type="month" :picker-options="dateEnd" value-format="yyyy-MM" placeholder="选择结束日期"></el-date-picker>
								<!-- <div class="iconfont-box">
                  <span class="iconfont icon-riliriqi2"></span>
                </div> -->
							</div>
						</div>
						<div class="form-bottom">
							<div class="search-button">
								<el-button type="primary" @click="handleQuery">筛选</el-button>
								<el-button @click="resetQuery">清空</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="list-box">
			<div class="result" v-show="total != 0">
				<div class="sort-box">
					<div class="sort">排序</div>
					<div class="sort-select">
						<!-- <el-radio v-model="sortType" @change="relevancyChange" label="relevancy"
              >相关度</el-radio
            > -->
						<el-radio @change="sortTypeChange" v-model="sortType" label="time">
							<span @click="sortTime">
								时间
								<i class="iconfont icon-jiangxu" v-show="sortType == 'time' && timeSort"></i>
								<i class="iconfont icon-shengxu" v-show="sortType == 'time' && !timeSort"></i>
							</span>
						</el-radio>
					</div>
				</div>
				<div></div>
				<div class="result-num">
					<span>共找到</span>
					<span class="num">{{ total }}</span>
					<span>条结果</span>
				</div>
			</div>
			<div class="result-box">
				<div class="empty-box" v-show="total == 0">
					<empty></empty>
				</div>
				<div class="list-item" v-for="(item, index) in pageReport" :key="index" @click="toDetail(item)">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.datumTitle" placement="bottom-start">
							<div class="text-ellipsis">
								{{ item.datumTitle }}
							</div>
						</el-tooltip>
					</div>
					<div class="bottom-box">
						<div class="tag-bottom">
							<div class="tag-item" v-for="(key, i) in item.datumTag" :key="i" v-show="i == 0">
								{{ key }}
							</div>
						</div>
						<div class="date" style="font-size: 12px; color: #666">
							<span>发文时间：</span>
							{{ item.dispatchDate }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0" :key="paginationKey"></pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
export default {
	components: {
		Pagination,
		Empty,
		LoginModal,
		MemberModal,
	},
	data() {
		return {
			paginationKey: "",
			total: 0, // 结果数量
			conPO: {
				datumTitle: "",
				categoryId: "",
				dateStart: "",
				dateEnd: "",
				platform: 1,
				timeSort: 1,
				pageSize: 10,
				pageNum: 1,
			}, // 查询条件
			dateStart: {
				disabledDate: time => {
					if (this.conPO.dateEnd) {
						return time.getTime() > new Date(this.conPO.dateEnd).getTime();
					}
				},
			}, // 控制开始时间
			dateEnd: {
				disabledDate: time => {
					if (this.conPO.dateStart) {
						return time.getTime() < new Date(this.conPO.dateStart).getTime() - 8.64e7;
					}
				},
			}, // 控制结束时间
			typeList: [], // 分类数据
			sortType: "time",
			timeSort: true, //时间排序状态 默认正序
			pageReport: [],
		};
	},
	mounted() {
		let app = document.getElementById("app");
		app.removeAttribute("class");
		// 数据埋点
		this.$api.home.buriedPoint({
			terminal: "60441001",
			consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			positionCode: "604410011012",
		});
		this.getDatumReportType();
		this.getDatumReportPage();
	},
	methods: {
		// 资料报告分类
		getDatumReportType() {
			this.$api.industry
				.getDatumReportType()
				.then(res => {
					this.typeList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 列表数据查询
		getDatumReportPage() {
			let {conPO} = this;
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry
				.getDatumReportPage(conPO)
				.then(res => {
					res.rows.forEach(item => {
						if (item.dispatchDate) {
							item.dispatchDate = item.dispatchDate.slice(0, 7);
						}
						if (item.datumTag) {
							item.datumTag = item.datumTag.split(",");
						}
					});
					this.pageReport = res.rows;
					this.total = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 点击筛选
		handleQuery() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.$refs.pagination.page = 1;
                this.conPO.pageNum = 1;
                this.conPO.pageSize = 10;
                this.getDatumReportPage();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 点击清空
		resetQuery() {
			this.conPO = {
				datumTitle: "",
				categoryId: "",
				dateStart: "",
				dateEnd: "",
				platform: 1,
				pageSize: 10,
				pageNum: 1,
			};
		},
		// 进入详情
		toDetail(item) {
			if (window.getSelection().toString() === "") {
				if (item.url) {
					this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(() => {
						window.open(item.url, "_blank");
					});
				} else {
					// 判断用户是否登录
					let url = this.$router.resolve({
						path: `/industry/industry-detail?type=3&id=${item.datumId}`,
					});

					if (this.$session.getUsers()) {
                        window.open(url.href, "_blank");
					} else {
						this.$refs.loginModal.visible = true;
					}
				}
			}
		},
		// 分页方法
		paginChange(page, pageSize) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.conPO.pageNum = page;
                this.conPO.pageSize = pageSize;
                this.getDatumReportPage();
			} else {
				this.paginationKey = new Date().getTime();
				this.$refs.loginModal.visible = true;
			}
		},
		// 点击相关度
		relevancyChange() {
			delete this.conPO.timeSort;
			this.getDatumReportPage();
		},
		// 切换成时间排序
		sortTypeChange() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.timeSort = false;
                this.conPO.timeSort = 2;
                this.getDatumReportPage();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 改变时间排序
		sortTime() {
			this.timeSort = !this.timeSort;
			if (this.timeSort) {
				this.conPO.timeSort = 1;
				this.getDatumReportPage();
			} else {
				this.conPO.timeSort = 2;
				this.getDatumReportPage();
			}
		},
	},
	destroyed() {
		document.getElementById("app").className = "uncopy";
	},
};
</script>

<style lang="less" scope>
@import "./index.less";
</style>
