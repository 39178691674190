<template>
  <div class="empty">
    <img @dragstart.prevent src="../../assets/img/empty-icon.png" />
    <div class="description">{{ name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "暂无数据",
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
